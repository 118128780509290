import React from "react";

// CSS Import
import "./filterstyle.css";

const Filter = () => {
  return (
    <div className="filter-fr-spt">
      <h1>Filter Area</h1>
    </div>
  );
};

export default Filter;
